import React, { useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
} from '@chakra-ui/react'
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import PlainText from '../components/CaseStudies/PlainText'
import PlainImage from '../components/CaseStudies/PlainImage'
import ImageText from '../components/CaseStudies/ImageText'
import Columns from '../components/CaseStudies/Columns'
import { H1 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const MTAFPage = ({ data }) => {

  // gsap's scrollTrigger for fade-up
  const gsapFadeUp = (csBlocks) => {
    for (let i = 0; i < csBlocks.length; ++i) {
      gsap.from(csBlocks[i], {
        scrollTrigger: {
          trigger: csBlocks[i],
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const csBlocks = document.querySelectorAll('.csBlock')
      gsapFadeUp(csBlocks)
    }
  }, [])

  return (
    <Layout>
      <SEO title={`MTA Foundation Branding & Website - ${data.site.siteMetadata.title}`} noIndex />
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 color={colors.brand.slate} textAlign="center" mt={spacing.header} mb={spacing.groups}>MTA Foundation Branding & Website</H1>
      </Container>
      {/** BLOCK 01 */}
      <PlainText
        color={colors.brand.lightGray}
        bgColor="#87c340"
        heading="Brief"
        body="MTA Foundation is MTA’s instrument of funding local learning and innovation. In 2019, MTA approached Alopex with the goal
        of revitalizing the foundation’s brand and digital presence. MTA is an Alaska company to the core, having even laid
        telecommunication line down by dogsled."
      />
      {/** BLOCK 02 */}
      <PlainImage img={data.mtaflogo.childImageSharp.fluid} />
      {/** BLOCK 03 */}
      <ImageText
        heading="Branding"
        body="MTA Foundation is, obviously, an MTA company. Understanding how to keep a common identity between brands, while designing
        something that could stand on its own was a key consideration in the design process. Alopex worked on a number of initial
        concepts before working with the PR and Marketing team at MTA directly on final options."
        img={data.mtafback1.childImageSharp.fluid}
        color={colors.brand.slate}
        overlayColor="#eee"
        overlayOpacity="0.5"
      />
      {/** BLOCK 04 */}
      <PlainImage img={data.mtafmockups.childImageSharp.fluid} />
      {/** BLOCK 05 */}
      <PlainText
        color={colors.brand.slate}
        bgColor={colors.brand.lightGray}
        heading="MTA Foundation Brand"
        body="The new MTA Foundation logo is a stylized Alaska deconstructed into various color fields. The low-poly shapes evoke the tech-focused nature of the
        program, with the colors representing the various focuses of funding. The typography relates the mark directly to the MTA brand, and colors were
        selected and tweaked from the corporate branding guidelines."
      />
      {/** BLOCK 06 */}
      <PlainImage img={data.mtafscreenies.childImageSharp.fluid} />
      {/** BLOCK 07 */}
      <PlainImage img={data.mtafresponsive.childImageSharp.fluid} />
      {/** BLOCK 08 */}
      <ImageText
        heading="Website"
        body="The MTA Foundation website is one of the most interactive and vibrant sites that Alopex has built. This WordPress website features a number of
        tools for creating interesting front end design elements—for example, the stars on the home page are dynamically generated by donors, and the
        aurora band sizes are scaled proportional to contribution percentages. One of the primary functions of the website is to allow users to apply
        for scholarships and grants; MTAFoundation.org handles forms beautifully. Staff can edit, activate and pause forms easily, receiving them in
        clean, formatted emails."
        img={data.mtafcompgirl.childImageSharp.fluid}
        color={colors.brand.lightGray}
        overlayColor="#77b52b"
        overlayOpacity="0.9"
      />
      {/** Block 09 */}
      <Columns
        blockLeft={
          <PlainText
            size="small"
            heading="Speed"
            body="MTAFoundation.org is lighting fast, scoring a 97/100 on Google PageSpeed. The Alopex developers achieve this with some clever
            tricks—Wordpress is used to generate and manage content, but Gatsby renders a lightweight static website. This is one tactic
            that Alopex can implement to increase organic site scores."
            bgColor="#0693e3"
            color={colors.brand.lightGray}
          />
        }
        blockRight={
          <PlainImage img={data.mtafpspeed.childImageSharp.fluid} size="small" />
        }
      />
      {/** BLOCK 10 */}
      <ImageText
        heading="Results"
        className="lastBlock"
        body="The MTA Foundation rebranding and subsequent overhauling of its digital presence has been very successful for MTA. The renewal of the way the
        Foundation is represented has had a major impact in community recognition and engagement. Following the work performed for MTA Foundation,
        Alopex was awarded the contract for MTA Communications itself."
        img={data.mtafresults.childImageSharp.fluid}
        color={colors.brand.lightGray}
        overlayColor="#4092cf"
        overlayOpacity="0.9"
      />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query mtafPage {
    site {
      siteMetadata {
        title
      }
    }
    mtaflogo: file(relativePath: { eq: "casestudies/mtaf/Alopex_MTAF1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtafback1: file(relativePath: { eq: "casestudies/mtaf/Alopex-MTAF-back1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtafmockups: file(relativePath: { eq: "casestudies/mtaf/mtaf_mockups.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtafscreenies: file(relativePath: { eq: "casestudies/mtaf/Alopex_MTAF-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtafresponsive: file(relativePath: { eq: "casestudies/mtaf/ALOPEX_MTAF-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtafcompgirl: file(relativePath: { eq: "casestudies/mtaf/computer_girl.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtafpspeed: file(relativePath: { eq: "casestudies/mtaf/MTAF-Pagespeed2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtafresults: file(relativePath: { eq: "casestudies/mtaf/iStock-1145684652.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default MTAFPage
